import { useMutation, useQuery } from 'urql'
import { useNavigate, useParams } from 'react-router-dom'
import { formatInTimeZone } from 'date-fns-tz'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { FormValues, SeasonForm } from './components/season-form'

const editSeasonDocument = graphql(`
  query EditSeason($id: ID!) {
    season(id: $id) {
      id
      name
      registrationOpensAt
    }
  }
`)

const updateSeasonDocument = graphql(`
  mutation UpdateSeason($id: ID!, $input: SeasonInput!) {
    updateSeason(id: $id, input: $input) {
      season {
        id
        name
        registrationOpensAt
      }
    }
  }
`)

export const EditSeason = () => {
  const navigate = useNavigate()
  const params = useParams<'seasonId'>()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: editSeasonDocument,
    variables: { id: params.seasonId! },
  })
  const [{ fetching: submitting }, updateSeason] =
    useMutation(updateSeasonDocument)

  const onClose = () => navigate(`../${params.seasonId}`)
  const onSubmit = async (values: FormValues) => {
    const { error } = await updateSeason({
      id: params.seasonId!,
      input: {
        ...values,
        registrationOpensAt: new Date(values.registrationOpensAt).toISOString(),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Sesong endret',
      description: 'Sesongen ble endret',
      variant: 'default',
    })
    onClose()
  }

  const loading = fetching || submitting
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Endre sesong</DialogTitle>
        </DialogHeader>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <SeasonForm
            onSubmit={onSubmit}
            defaultValues={{
              name: data.season.name,
              registrationOpensAt: formatInTimeZone(
                new Date(data.season.registrationOpensAt),
                timeZone,
                "yyyy-MM-dd'T'HH:mm"
              ),
            }}
          />
        )}

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="season-form" type="submit" disabled={loading}>
            Lagre
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
