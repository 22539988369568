import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { useMutation, useQuery } from 'urql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { QueryError } from '@/components/query-error'

export const cancelBookingDialogDocument = graphql(`
  query CancelBookingDialog($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      bookingExport {
        id
        invoiceRef
      }
      payments {
        id
        state
      }
    }
  }
`)

export const cancelBookingDocument = graphql(`
  mutation CancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      booking {
        id
        status
      }
    }
  }
`)

export const CancelBookingButton = ({ bookingId }: { bookingId: string }) => {
  const [refundPayment, setRefundPayment] = useState(false)
  const [creditInvoice, setCreditInvoice] = useState(false)
  const navigate = useNavigate()
  const { toast } = useToast()
  const [{ data, error }] = useQuery({
    query: cancelBookingDialogDocument,
    variables: { bookingId },
  })
  const [_, cancelBooking] = useMutation(cancelBookingDocument)

  const hasDepositPayment = data?.booking?.payments.some((p) =>
    ['pending', 'paid', 'invoiced'].includes(p.state)
  )
  const hasInvoice = !!data?.booking.bookingExport?.invoiceRef

  const onCancelBooking = async () => {
    const { error } = await cancelBooking({
      input: { bookingId, refundPayment, creditInvoice },
    })

    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kansellert!',
      description: 'Bookingen er kansellert.',
      variant: 'default',
    })
    navigate('/bookings')
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          Kanseller booking
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Kanseller booking</DialogTitle>
          <DialogDescription>
            Er du sikker på at du vil kansellere denne bookingen?
          </DialogDescription>
        </DialogHeader>
        {data && (
          <>
            {hasDepositPayment && (
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="refundPayment"
                  checked={refundPayment}
                  onCheckedChange={(v) => setRefundPayment(!!v)}
                />
                <Label htmlFor="refundPayment">Refunder forhåndsbetaling</Label>
              </div>
            )}

            {hasInvoice && (
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="creditInvoice"
                  checked={creditInvoice}
                  onCheckedChange={(v) => setCreditInvoice(!!v)}
                />
                <Label htmlFor="creditInvoice">Krediter faktura</Label>
              </div>
            )}

            <DialogFooter>
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Avbryt
                </Button>
              </DialogClose>
              <Button type="submit" onClick={onCancelBooking}>
                Kanseller
              </Button>
            </DialogFooter>
          </>
        )}
        {error && <QueryError error={error} />}
      </DialogContent>
    </Dialog>
  )
}
