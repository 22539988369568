import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { PaymentsInfoFragment } from '@/__generated__/gql/graphql'
import { formatDate } from '@/utils/format-date'
import { formatCurrency } from '@/utils/format-currency'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'

export const paymentsInfoDocument = graphql(`
  fragment PaymentsInfo on Booking {
    payments {
      id
      state
      amount
      provider
      externalRef
      externalUrl
      updatedAt
    }
  }
`)

export type PaymentsInfoProps = {
  payments: PaymentsInfoFragment['payments']
}

export const PaymentsInfo = ({ payments }: PaymentsInfoProps) => {
  return (
    <div>
      <h2 className="font-bold text-xl mb-2">Betalinger</h2>
      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>Leverandør</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Beløp</TableHead>
            <TableHead>Dato</TableHead>
            <TableHead>Referanse</TableHead>
            <TableHead>Link</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={payment.id}>
              <TableCell>{payment.provider}</TableCell>
              <TableCell>{payment.state}</TableCell>
              <TableCell>{formatCurrency(payment.amount)}</TableCell>
              <TableCell>{formatDate(payment.updatedAt)}</TableCell>
              <TableCell>{payment.externalRef}</TableCell>
              <TableCell>
                {payment.externalUrl && (
                  <Button asChild variant="link">
                    <a
                      href={payment.externalUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon className="h-4 w-4" />
                    </a>
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>
    </div>
  )
}
